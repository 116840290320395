/* 
#f0f1e4
#aab9b0
#a0a39e
#978e8c
#8e797b 
*/

.App {
  text-align: center;
  background-color: #f0f1e4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  position: absolute;
  height: 10%;
  align-self: center;
  top: 20vh;
  z-index: 0;
}

.headerbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #a0a39e;
  z-index: 0;

}

.scenes {
  display: flex;
  width: 80vw;
  overflow-x: auto;
  justify-content: center;
  flex-wrap: wrap;
}

.scene {
  margin: 0.2%;
  padding: 0%;
}

.scene {
  border-style: solid;
  border-color: #978e8c;
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #f0f1e4;

}

.trackName {
  font-size: medium;
  margin: 0;
}

.sceneHeader {
  margin: 1%;
  /* display: flex;
  flex-direction: row; */
  padding: 0%;
}

.sceneTitleBar {
  display: flex;
  justify-content: space-between;
}

iframe {
  display: block;
}

p {
  margin: 0;
}

.App-link {
  color: #61dafb;
}

.coffee_widget {
  /* margin-bottom: 1%; */
}

a:link,
a:visited {
  color: #8e797b;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

a:hover,
a:active {
  text-decoration: underline;
}

h4 {
  padding: 0;
  margin: 0;
}

label {
  font-size: small;
}

form {
  color: #8e797b;
}

.infoAlert {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
}

.footerdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fa-play:active,
.fa-pause:active {
  color: #8e797b;
}
